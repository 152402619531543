

































































import { Component, Vue, Ref } from 'vue-property-decorator';
import VueClipboard from 'vue-clipboard2';
import MyButton from '@/components/MyButton.vue';
import { apireq, fileUpload } from '@/utils/apiRequest';
import { Editor } from '@toast-ui/vue-editor';

declare type myInputPost = {
  id: number,
  boardId: number,
  title: string,
  body: string,
  tags: string,
  description: string,
};

Vue.use(VueClipboard);
@Component({
  components: {
    MyButton,
  },
})
export default class PostPost extends Vue {
  // states
  inputPost: myInputPost = {
    id: 0,
    boardId: 0,
    title: '',
    body: '',
    tags: '',
    description: '',
  };

  loaded: boolean = false;

  boards: myBoard[] = [];

  uploadedFiles: string[] = [];

  showFileUpload = false;

  file?: File;

  uploadName = '';

  editorPreviewStyle = 'vertical';

  editorOptions = {
    language: 'ko_KR',
    minHeight: '1000px',
    usageStatistics: false,
  };

  @Ref() tuiEditor!: Editor;

  tuiEditorHasLoaded() {
    this.loaded = true;

    if (this.inputPost.body) {
      this.tuiEditor.invoke('setMarkdown', this.inputPost.body);
    }
  }

  // methods
  handleResize() {
    if (window.innerWidth < 768) {
      this.editorPreviewStyle = 'tab';
    } else {
      this.editorPreviewStyle = 'vertical';
    }
  }

  changeFile(event: { target: HTMLInputElement }) {
    const node = event.target;
    if (!node || !node.files || node.files.length <= 0) return;
    [this.file] = node.files;
    this.uploadName = this.uploadName || this.file.name;
  }

  uploadFile() {
    if (!this.file) return;
    this.uploadName = this.uploadName || this.file.name;
    if (this.uploadName === '') {
      alert('파일 이름이 없습니다(bug)');
      return;
    }

    const data = new FormData();
    data.append('file', this.file);
    if (this.file.size > (32 * 1024 * 1024)) {
      alert('파일은 32MB 까지 업로드 가능합니다');
      return;
    }

    fileUpload(this.uploadName, data)
      .then((res) => {
        this.uploadedFiles.push(res.data.data);
      });
  }

  copyToClipboard(event: {target: HTMLDivElement}) {
    const imgExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const fileName = `https://api.jjungs.kr/file/${event.target.innerText}`;
    const isImg = imgExtensions.some(ss => fileName.toLowerCase().includes(ss));
    let text = '';

    if (isImg) text = `![](${fileName})`;
    else text = `[](${fileName})`;
    this.$copyText(text)
      .then((res) => {
        alert(`Successfully copied as ${isImg ? 'img' : 'file'}`);
      });
  }

  doPost() {
    const isEdit = this.$route.path.includes('edit');
    if (isEdit) this.editPost();
    else this.createPost();
  }

  createPost() {
    this.inputPost.body = this.tuiEditor.invoke('getMarkdown');
    apireq('post', '/admin/post', this.inputPost)
      .then((result) => {
        this.$router.push({ name: 'post', params: { post_id: result.data.data.ID } });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }

  editPost() {
    this.inputPost.body = this.tuiEditor.invoke('getMarkdown');
    apireq('put', '/admin/post', this.inputPost)
      .then((result) => {
        this.$router.push({ name: 'post', params: { post_id: result.data.data.ID } });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }

  cancel() {
    this.$router.push({ path: '/admin' });
  }

  // lifecycle
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    apireq('get', '/board')
      .then((res) => {
        this.boards = res.data.data;
        this.inputPost.boardId = this.boards[0].ID;
      });

    if (this.$route.params.postId) {
      const { postId } = this.$route.params;
      this.inputPost.id = parseInt(postId, 10);
      apireq('get', `/post/${postId}?type=post`)
        .then((res) => {
          const post: myPost = res.data.data;
          this.inputPost.boardId = post.BoardID;
          this.inputPost.title = post.Title;
          this.inputPost.body = post.Body;
          this.inputPost.tags = post.PostTags.join(' ');
          this.inputPost.description = post.Description;

          if (this.loaded) {
            this.tuiEditor.invoke('setMarkdown', this.inputPost.body);
          }
        });
    }
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
}
